/*

  Theme: IADE theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="iade-theme"] {
	/* fontFamily */
	--font-primary: "Lato", "Adjusted Tahoma Fallback";
	--font-secondary: "Lato", "Adjusted Tahoma Fallback";
	/* fontWeight */
	--title-weight: 400;
	--body-font-weight: 200;
	--bodyTight-font-weight: 200;
	--subtitle-font-weight: 200;
	--subtitleLink-font-weight: 400;
	--small-font-weight: 200;
	--body-size-desktop: 14px;
	--body-size-big-screen: 15px;
	/* containers */
	--mobile-max-width: 100%;
	--tablet-max-width: 720px;
	--tablet-lds-max-width: 928px;
	--desktop-max-width: 1200px;
	--big-screen-max-width: 1488px;
	/* brandColors */
	--brand1: #000000;
	--brand2: #3cdbc0;
	--brand3: #0047bb;
	--brand4: #f000ff;
	--brand5: #ffffff;
	/* background */
	--ui01: #ffffff;
	--ui02: #000000;
	--ui03: #0047bb;
	--ui04: #eeeff2;
	--ui05: #000000;
	--ui06: #f000ff;
	--ui07: #ffffff00;
	--ui08: #ffffff00;
	--ui09: #ffffff00;
	--ui10: #ffffff00;
	--ui11: #ffffff00;
	--ui12: #ffffff00;
	--ui13: #ffffff00;
	--ui14: #ffffff00;
	--ui15: #ffffff00;
	--ui16: #0047bb;
	--ui17: #ededed;
	--bg-disabled: #eeeeeeff;
	/* backgroundButton */
	--interactive01: #3cdbc0;
	--interactive01Hover: #63e2cd;
	--interactive01Active: #36c5ad;
	--interactive01Focus: #3cdbc0;
	--interactive01Disabled: #9eacaa;
	--interactive01border: #3cdbc0;
	--interactive02: #ffffff;
	--interactive02Hover: #ffffff;
	--interactive02Active: #a0eadd;
	--interactive02Focus: #ccfaf2;
	--interactive02Disabled: #9eacaa;
	--interactive03: ##ffffff;
	--interactive03Hover: #ccfaf2;
	--interactive03Active: #a0eadd;
	--interactive03Focus: #7ae4d2;
	--interactive03Disabled: #9eacaa;
	--interactive04: #ffffff;
	--interactive04Hover: #ccfaf2;
	--interactive04Active: #a0eadd;
	--interactive04Focus: #a0eadd;
	--interactive04Disabled: #9eacaa;
	--interactive05: #ffffff00;
	--interactive05Hover: #ffffff00;
	--interactive05Active: #ffffff00;
	--interactive05Focus: #ffffff00;
	--interactive05Disabled: #ffffff00;
	/* borderButton */
	--borderOnInteractive01: #ffffff00;
	--borderOnInteractive01Hover: #ffffff00;
	--borderOnInteractive01Active: #ffffff00;
	--borderOnInteractive01Focus: #ffffff00;
	--borderOnInteractive01Disabled: #ffffff00;
	--borderOnInteractive02: #3cdbc0;
	--borderOnInteractive02Hover: #63e2cd;
	--borderOnInteractive02Active: #36c5ad;
	--borderOnInteractive02Focus: #3cdbc0;
	--borderOnInteractive02Disabled: #9eacaa;
	--borderOnInteractive03: #3cdbc0;
	--borderOnInteractive03Hover: #63e2cd;
	--borderOnInteractive03Active: #36c5ad;
	--borderOnInteractive03Focus: #3cdbc0;
	--borderOnInteractive03Disabled: #9eacaa;
	--borderOnInteractive04: #3cdbc0;
	--borderOnInteractive04Hover: #63e2cd;
	--borderOnInteractive04Active: #36c5ad;
	--borderOnInteractive04Focus: #3cdbc0;
	--borderOnInteractive04Disabled: #9eacaa;
	--borderOnInteractive05: #ffffff00;
	--borderOnInteractive05Hover: #ffffff00;
	--borderOnInteractive05Active: #ffffff00;
	--borderOnInteractive05Focus: #ffffff00;
	--borderOnInteractive05Disabled: #ffffff00;
	/* textButton */
	--textOnInteractive01: #000000;
	--textOnInteractive01Hover: #000000;
	--textOnInteractive01Active: #000000;
	--textOnInteractive01Focus: #000000;
	--textOnInteractive01Disabled: #ededed;
	--textOnInteractive02: #000000;
	--textOnInteractive02Hover: #000000;
	--textOnInteractive02Active: #000000;
	--textOnInteractive02Focus: #000000;
	--textOnInteractive02Disabled: #ededed;
	--textOnInteractive03: #000000;
	--textOnInteractive03Hover: #000000;
	--textOnInteractive03Active: #000000;
	--textOnInteractive03Focus: #000000;
	--textOnInteractive03Disabled: #ededed;
	--textOnInteractive04: #000000;
	--textOnInteractive04Hover: #000000;
	--textOnInteractive04Active: #000000;
	--textOnInteractive04Focus: #000000;
	--textOnInteractive04Disabled: #ededed;
	--textOnInteractive05: #ffffff00;
	--textOnInteractive05Hover: #ffffff00;
	--textOnInteractive05Active: #ffffff00;
	--textOnInteractive05Focus: #ffffff00;
	--textOnInteractive05Disabled: #ffffff00;
	/* textLink */
	--link01: #0047bb;
	--link01Hover: #236be0;
	--link01Active: #236be0;
	--link01Focus: #0047bb;
	/* text */
	--text01: #000000;
	--text02: #1b1c1d;
	--text03: #000000;
	--text04: #000000;
	--text05: #000000;
	--text06: #ffffff;
	--text07: #ffffff00;
	--text08: #ffffff00;
	--textAccent: #0047bb;
	--textRegular: #000000;
	--textInverse: #ffffff;
	/* icon */
	--icon01: #000000;
	--icon02: #ffffff;
	--icon03: #ffffff;
	--icon04: #000000;
	--icon05: #ffffff00;
	--icon06: #ffffff00;
	--icon07: #ffffff00;
	--icon08: #ffffff00;
	/* border */
	--border01: #f000ff;
	--border02: #f000ff;
	--border03: #ffffff00;
	--border04: #d6d6d6;
	--border05: #ffffff00;
	--border06: #ffffff00;
	--border07: #ffffff00;
	--border08: #ffffff00;
	--border09: #ffffff00;
	/* gradients */
	--gradient-bg: linear-gradient(180deg, rgba(252, 80, 21, 0) 15%, #000);
	--gradient01: linear-gradient(270deg, transparent 1%, #000);
	/* support */
	--facebook: #3b5998;
	--twitter: #00acee;
	--whatsApp: #00bb2d;
	--linkedIn: #0e76a8;
	--success: #96d621;
	--error: #f000ff;
	--errorInverse: #ffffff;
	/* lightbox */
	--lightboxBg: #ffffff;
	--lightboxBorder: #ededed;
	/* dots */
	--dot: #d6d6d6ff;
	--dot-active: #a3a3a3ff;
	/* spacing */
	--spc4: 4px;
	--spc8: 8px;
	--spc16: 16px;
	--spc24: 24px;
	--spc32: 32px;
	--spc48: 48px;
	--spc64: 64px;
	--spc96: 96px;
	--spc128: 120px;
	--spc256: 256px;
	--spc384: 384px;
	--spc512: 512px;
	--spc640: 640px;
	--spc768: 768px;
	/* scaleSpacing */
	--outSpc20: 20px;
	--outSpc40: 40px;
	--outSpc56: 56px;
	--outSpc72: 72px;
	--outSpc80: 80px;
	--outSpc144: 144px;
	--outSpc176: 176px;
	--outSpc344: 344px;
	/* z-index */
	--zIndexInitial: initial;
	--zIndexNegative10: -10;
	--zIndexNegative: -1;
	--zIndex0: 0;
	--zIndex1: 1;
	--zIndex2: 2;
	--zIndex3: 3;
	--zIndex10: 10;
	--zIndex20: 20;
	--zIndex50: 50;
	--zIndex100: 100;
	--zIndex999: 999;
	--zIndex1000: 1000;
	--zIndex1001: 1001;
	--zIndex1002: 1002;
	/* radii */
	--radii-none: 0px;
	--radii-xs: 4px;
	--radii-sm: 8px;
	--radii-md: 16px;
	--radii-lg: 24px;
	--radii-xl: 32px;
	--radii-full: 9999px;
	/* sizes */
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
	/* borderWidth */
	--border-width-none: 0px;
	--border-width-xs: 1px;
	--border-width-sm: 2px;
	--border-width-md: 4px;
	--border-width-lg: 8px;
	/* animations */
	--fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1);
	--show-up: show-up 2s ease-in-out;
	--delay-fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1) 0.1s;
	--menu-hover: menu-hover both 0.3s cubic-bezier(0.61, 0.43, 0.15, 1);
	--in-expo: cubic-bezier(0.61, 0.43, 0.15, 1);
	/* timing */
	--duration-xs: 0.1s;
	--duration-sm: 0.2s;
	--duration-md: 0.3s;
	--duration-lg: 0.5s;
	--duration-xl: 0.6s;
	/* easing */
	--easing-xs: var(--ease-in-out-quad);
	--easing-xs: var(--ease-in-out-quad);
	--easing-sm: var(--ease-in-out-cubic);
	--easing-md: var(--ease-in-out-quart);
	--easing-lg: var(--ease-in-out-quint);
	--easing-xl: var(--ease-in-out-expo);
	/* easingFunctions */
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
}
[data-theme="iade-theme"][data-subtheme="defaultAlt"],
[data-theme="iade-theme"] [data-subtheme="defaultAlt"] {
	/* background */
	--ui01: #eeeff2;
	--ui04: #ffffff;
	/* text */
	--text01: #000000;
	--text02: #000000;
	/* icon */
	--icon01: #000000;
	/* backgroundButton */
	--interactive01: #ffffff;
	--interactive01Hover: #ededed;
	--interactive01Active: #d6d6d6;
	--interactive01Focus: #d6d6d6;
	--interactive01border: #1d1d1b;
	/* textButton */
	--textOnInteractive01: #1d1d1b;
	/* textLink */
	--link01: #0047bb;
	--link01Active: #ffffff;
}
[data-theme="iade-theme"][data-subtheme="accent"],
[data-theme="iade-theme"] [data-subtheme="accent"] {
	/* background */
	--ui01: #000000;
	--ui04: #000000;
	--ui06: #f000ff;
	/* text */
	--text01: #ffffff;
	--text02: #ffffff;
	--text06: #ffffff;
	/* icon */
	--icon01: #ffffff;
	/* textButton */
	--textOnInteractive02: #000000;
	/* borderButton */
	--borderOnInteractive02: #ffffff;
	/* backgroundButton */
	--interactive02: #ffffff;
	/* textLink */
	--link01: #3cdbc0;
	--link01Active: #d6d6d6;
}
[data-theme="iade-theme"][data-subtheme="accentAlt"],
[data-theme="iade-theme"] [data-subtheme="accentAlt"] {
	/* background */
	--ui01: #0047bb;
	--ui04: #ffffff;
	--ui06: #0047bb;
	/* text */
	--text01: #ffffff;
	--text06: #000000;
	/* icon */
	--icon01: #ffffff;
	/* support */
	--error: #ffffff;
	/* brandColors */
	--brand4: #ffffff;
	/* border */
	--border01: #ffffff;
	/* backgroundButton */
	--interactive01: #ffffff;
	--interactive01Hover: #ededed;
	--interactive01Active: #d6d6d6;
	--interactive01Focus: #d6d6d6;
	--interactive01border: #1b1c1d;
	/* textButton */
	--textOnInteractive01: #1b1c1d;
	--textOnInteractive02: #0047bb;
	/* borderButton */
	--borderOnInteractive02: #0047bb;
	/* textLink */
	--link01: #3cdbc0;
	--link01Active: #ffffff;
}
[data-theme="iade-theme"][data-subtheme="accentLight"],
[data-theme="iade-theme"] [data-subtheme="accentLight"] {
	/* background */
	--ui01: #3cdbc0;
	--ui06: #3cdbc0;
	/* text */
	--text01: #000000;
	--text02: #000000;
	--text06: #000000;
	/* icon */
	--icon01: #000000;
	/* backgroundButton */
	--interactive01: #ffffff;
	--interactive01Hover: #ededed;
	--interactive01Active: #d6d6d6;
	--interactive01Focus: #d6d6d6;
	--interactive01border: #1d1d1b;
	/* textButton */
	--textOnInteractive01: #1d1d1b;
	--textOnInteractive02: #ffffff;
	/* borderButton */
	--borderOnInteractive02: #ffffff;
	/* textLink */
	--link01: #0047bb;
	--link01Active: #ffffff;
}
